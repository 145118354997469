import { Provider } from 'react-redux';
import React, { ReactElement } from "react";

//importing logic layers
import '../../../src/styles/landing/landing.css';
import LandingPage from '../../logic/landing/LandingPageStructure';
import { LandingPageComponents } from './LandingPageComponents';

interface LandingPageContainerProps extends PageViewProps {
}

class LandingPageContainer extends React.Component<LandingPageContainerProps, GenericObject> {

    private pageDataAndLogic: LandingPage;
    private pageComponents: LandingPageComponents;
    private LandingPageViewComponent: any;

    public constructor(props: LandingPageContainerProps) {

        super(props);
        this.pageDataAndLogic = new LandingPage();
        this.pageComponents = new LandingPageComponents(this.pageDataAndLogic);
        this.LandingPageViewComponent = this.pageComponents.createLandingPageView();
    }

    render() {

        return (
            <Provider store={this.pageDataAndLogic.store}>
                <this.LandingPageViewComponent />
            </Provider >
        )
    }

}

export default LandingPageContainer;

