import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
//some basic bootstrap css
import './App.css'
import 'bootstrap/dist/css/bootstrap.css';
import LandingPageContainer from './view/landing/LandingPageContainer';
import ProductPageContainer from './view/product/ProductPageContainer';
import CheckoutPageContainer from './view/checkout/CheckoutContainer';
import AuthPageContainer from './view/auth/AuthPageContainer';
import  RefundPolicy from './view/terms_of_service/RefundPolicy';
import TermsOfService from './view/terms_of_service/TermsOfService';

const App = () => (
  <div className='app'>
    <Main />
  </div>
);

// const PrivateRoute = function ({ component: Component, ...rest }) {

//   //check auth
//   let isAuthenticated = AppBLL.LoggedIn();

//   return <Route {...rest} render={(props) => (
//     isAuthenticated === true
//       ? <Component {...props} />
//       : <Redirect to='/login' />
//   )} />;
//   }

const LandingPageRoute = function ({ component: Component, ...rest }) {

  //check auth
  //let isAuthenticated = AppBLL.LoggedIn();
  let isAuthenticated = true;

  return <Route {...rest} render={(props) => (
    isAuthenticated === true
      ? <Redirect to='/home' />
      : <Component {...props} />
  )} />;
}

const Main = () => (
  <Switch>
    <Route exact path='/' component={LandingPageContainer}></Route>
    {/* <Route exact path='/product/:id' component={ProductPageContainer}></Route> */}
    <Route exact path='/checkout/:type/:id' component={CheckoutPageContainer}></Route>
    <Route exact path='/auth' component={AuthPageContainer}></Route>
    <Route exact path='/auth/:type/:id' component={AuthPageContainer}></Route>
    <Route exact path='/refund' component={RefundPolicy}></Route>
    <Route exact path='/termsofservice' component={TermsOfService}></Route>
  </Switch>
);

export default App;
