import { AppBLL } from "../app/AppBLL";
import { ProductType } from "../models/common";

export const navigateToCheckout = ({ productId, type }: {
    productId: string | number;
    type: ProductType
}) => {
    const productType = type === ProductType.Product ? 'c' : 'b';
    const isLoggedIn = AppBLL.LoggedIn();
    if (isLoggedIn) {
        window.location.href = `/checkout/${productType}/${productId}`;
    } else {
        // navigate to auth page to log in
        window.location.href = `/auth/${productType}/${productId}`;
    }
};

export const getProductInfoFromUrl = () => {
    const splitUrl = window.location.pathname.split("/");
    const productId = splitUrl.pop();
    const productType = splitUrl.pop();
    
    return {
        productId,
        productType: productType === 'c' ? ProductType.Product : ProductType.Bundle,
        productTypeUrlChar: productType
    }
}
