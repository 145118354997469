
import autobind from 'auto-bind';
import LandingPageDAL from './LandingPageDAL';

import { fetchAllCourses } from '../serverRequests/productRequest';

class LandingPageBLL {
    private DAL: LandingPageDAL;
    constructor(PageDAL: LandingPageDAL) {
        this.DAL = PageDAL;
        autobind(this);
    }

    async handleFetchCourses() {
        try {
            const courses = await fetchAllCourses();
            this.DAL.updateCourses(courses.data);
        } catch (err) {
            console.error(err);
        }
    }
}

export default LandingPageBLL;