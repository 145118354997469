import { Provider } from 'react-redux';
import React from "react";

//importing logic layers
import '../../../src/styles/landing/landing.css';
import ProductPage from '../../logic/product/ProductPageStructure';
import { ProductPageComponents } from './ProductPageComponents';


interface ProductPageContainerProps extends PageViewProps {
}

class ProductPageContainer extends React.Component<ProductPageContainerProps, GenericObject> {

    private pageDataAndLogic: ProductPage;
    private pageComponents: ProductPageComponents;
    private ProductPageViewComponent: any;

    public constructor(props: ProductPageContainerProps) {

        super(props);
        this.pageDataAndLogic = new ProductPage();
        this.pageComponents = new ProductPageComponents(this.pageDataAndLogic);
        this.ProductPageViewComponent = this.pageComponents.createProductPageView();
    }

    render() {

        return (
            <Provider store={this.pageDataAndLogic.store}>
                <this.ProductPageViewComponent />
            </Provider >
        )
    }

}

export default ProductPageContainer;

