import autobind from 'auto-bind';
import CelesRedux from '../utility/globalRedux';
import { Course } from './LandingPageStructure';


class LandingPageDAL {

    protected store: any;
    private baseApiURL: string;

    constructor(ReduxStore: any, BaseApiURL: string) {
        this.store = ReduxStore;
        this.baseApiURL = BaseApiURL;

        autobind(this);
    }

    // getter/setter
    getCourses(): Array<Course> {
        return this.store.getState().courses;
    }

    updateCourses(courses: Array<Course>) {
        this.store.dispatch(CelesRedux.updateDataActionGenerator('courses', courses, 'UPDATE_COURSES'));
    }

}


export default LandingPageDAL;