
import React from "react";
import { Button } from 'src/styles/common/styles';
import AppDAL from "src/logic/app/AppDAL"
import styled from "styled-components";

export const AppHeader = () => {
    const isLoggedIn = Boolean(AppDAL.getToken());
    const user = AppDAL.getSelectedUser();

    if (isLoggedIn) {
        return <AuthHeader name={user?.name ?? ''} />
    } else {
        return <GuestHeader />
    }
}

const AuthHeader = ({ name }: { name: string }) => {
    const logout = () => {
        AppDAL.clearSession();
        window.location.href = '/';
    }

    return (
        <div className="header-container">
            <Header>
                <div className="header-left">
                    <div className="logo-container">
                        <img className="logo" src="/img/logo.png" alt="" />
                    </div>

                </div>
                <div className="header-right">
                    <div className="user-name">
                        {/* Signed in as {name} */}
                        <LoginSignupButton onClick={logout}>
                            Logout
                        </LoginSignupButton>
                    </div>
                </div>
            </Header>
        </div>
    );
}

const GuestHeader = () => {
    const signIn = () => {
        window.location.href = '/auth';
    }

    return (
        <div className="header-container">
            <Header>
                <div className="header-left">
                    <div className="logo-container">
                        <img className="logo" src="/img/logo.png" alt="" />
                    </div>

                </div>
                <div className="header-right">
                    <div className="user-name">
                        {/* Signed in as {name} */}
                        <LoginSignupButton onClick={signIn}>
                            Sign In
                        </LoginSignupButton>
                    </div>
                </div>
            </Header>
        </div>
    );
}

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
`;

const LoginSignupButton = styled(Button)`
    margin-top: 0 !important;
    height: 40px;
`;