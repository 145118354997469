import React, { ReactElement } from "react";
import { AppHeader } from '../common/Header';
import styled from "styled-components";

const RefundPolicy = (): JSX.Element => {
    return (
        <RefundPolicyContainer>
            <AppHeader />
                <RefundTextContainer>
                    <h1>Refund Policy</h1>
                    <p>All payments made on this site are final and non-refundable.</p>
                </RefundTextContainer>
        </RefundPolicyContainer>

);
}

const RefundPolicyContainer = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
h1 {
    margin: 0 0 20px 0;
    font-size: 20px;
    font-weight: 500;
}
padding: 50px;
`;

const RefundTextContainer = styled.div`
    margin-top: 50px;
`;




export default RefundPolicy;
