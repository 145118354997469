import { Course } from "../landing/LandingPageStructure";
import { Bundle } from "../models/bundle";
import FetchUtility from "../utility/fetchUtility";
import { ServerConfig } from "./serverConfig";

const fetchAllCoursesEndPoint = `${ServerConfig.serverBaseURL}/courses`;

const fetchAllBundlesEndPoint = `${ServerConfig.serverBaseURL}/bundles`;

const fetchConfirmPaymentEndPoint = `${ServerConfig.serverBaseURL}/confirm-payment`;

const fetchPaymentIntentEndPoint = `${ServerConfig.serverBaseURL}/create-payment-intent`;

export type FetchCoursesSuccessResponse = {
    data: Array<Course>;
};

export type FetchBundlesSuccessResponse = {
    data: Array<Bundle>
};

export type FetchPaymentIntentSuccessResponse = {
    clientSecret: string,
    paymentIntentId: string
};

export const fetchAllCourses = async (): Promise<FetchCoursesSuccessResponse> => {
    try {
        const response = await fetch(fetchAllCoursesEndPoint, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (FetchUtility.isSuccessResponse(response)) {
            const data: FetchCoursesSuccessResponse = await response.json();
            return data;
        }
        throw await response.json()
    } catch (err) {
        console.error(err);
    }
}

export const fetchCourseById = async ({ courseId }: { courseId: string }): Promise<FetchCoursesSuccessResponse> => {
    try {
        const response = await fetch(`${ServerConfig.serverBaseURL}/courses/${courseId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (FetchUtility.isSuccessResponse(response)) {
            const data: FetchCoursesSuccessResponse = await response.json();
            return data;
        }
        throw await response.json()
    } catch (err) {
        console.error(err);
    }
}

export const fetchBundlesForCourse = async ({ courseId }: { courseId: string }): Promise<FetchBundlesSuccessResponse> => {
    try {
        const response = await fetch(`${fetchAllBundlesEndPoint}/${courseId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (FetchUtility.isSuccessResponse(response)) {
            const data: FetchBundlesSuccessResponse = await response.json();
            return data;
        }
        throw await response.json()
    } catch (err) {
        console.error(err);
    }
}

export const fetchPurchaseProduct = async ({ paymentIntentId, userId }:
    { paymentIntentId: string, userId: string }) => {
    try {
        const response = await fetch(fetchConfirmPaymentEndPoint, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                payment_intent_id: paymentIntentId,
                user_id: userId
            })
        })
        if (FetchUtility.isSuccessResponse(response)) {
            const data = await response.json();
            return data;
        }
        throw await response.json();
    } catch (err) {
        console.error(err);
    }
}

export const fetchPaymentIntent = async ({
    isBundle,
    productId,
    userId,
    recipientEmail
}: {
    isBundle: boolean;
    productId: number;
    userId: string;
    recipientEmail?: string;
}): Promise<FetchPaymentIntentSuccessResponse> => {
    try {
        const response = await fetch(fetchPaymentIntentEndPoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                product_id: productId,
                is_bundle: isBundle,
                user_id: userId,
                recipient_email: recipientEmail
            })
        });

        if (FetchUtility.isSuccessResponse(response)) {
            const data: FetchPaymentIntentSuccessResponse = await response.json();
            return data;
        }

        throw await response.json();
    } catch (err) {
        throw err;
    }
};

export const fetchBundle = async ({ bundleId }: { bundleId: string }) => {
    try {
        const response = await fetch(`${ServerConfig.serverBaseURL}/bundle/${bundleId}`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        if (FetchUtility.isSuccessResponse(response)) {
            const data: FetchBundlesSuccessResponse = await response.json();
            return data;
        }
        throw await response.json()
    } catch (err) {
        console.error(err);
    }
}