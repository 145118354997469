import autobind from 'auto-bind';
import CelesRedux from '../utility/globalRedux';
import { RequestState } from '../utility/fetchTypes';
import { AuthPageFormState } from './AuthPageStructure';
import { Course } from '../landing/LandingPageStructure';
import { Bundle } from '../models/bundle';

class SignUpLoginPageDAL {
    protected store: any;
    private baseApiURL: string;

    constructor(ReduxStore: any, BaseApiURL: string) {
        this.store = ReduxStore;
        this.baseApiURL = BaseApiURL;
        autobind(this);
    }

    getIsSignUp(): boolean {
        return this.store.getState().isSignUp;
    }

    updateIsSignUp(isSignUp: boolean) {
        this.store.dispatch(
            CelesRedux.updateDataActionGenerator(
                'isSignUp',
                isSignUp,
                'UPDATE_IS_SIGN_UP'
            )
        );
    }

    getFormState(): AuthPageFormState {
        return this.store.getState().formState;
    }

    updateFormState(formState: AuthPageFormState) {
        this.store.dispatch(
            CelesRedux.updateDataActionGenerator(
                'formState',
                formState,
                'UPDATE_FORM_STATE'
            )
        );
    }

    // Getter/Setter for login fetch status
    getLoginFetchStatus(): RequestState {
        return this.store.getState().loginFetchStatus;
    }

    updateLoginFetchStatus(fetchStatus: RequestState) {
        this.store.dispatch(
            CelesRedux.updateDataActionGenerator(
                'loginFetchStatus',
                fetchStatus,
                'UPDATE_LOGIN_FETCH_STATUS'
            )
        );
    }

    // Getter/Setter for sign up fetch status
    getSignUpFetchStatus(): RequestState {
        return this.store.getState().signUpFetchStatus;
    }

    updateSignUpFetchStatus(fetchStatus: RequestState) {
        this.store.dispatch(
            CelesRedux.updateDataActionGenerator(
                'signUpFetchStatus',
                fetchStatus,
                'UPDATE_SIGN_UP_FETCH_STATUS'
            )
        );
    }

    getCourse(): Course | undefined {
        return this.store.getState().product;
    }

    updateCourse(course: Course) {
        this.store.dispatch(
            CelesRedux.updateDataActionGenerator(
                'product',
                course,
                'UPDATE_COURSE'
            )
        );
    }

    getBundle(): Bundle | undefined {
        return this.store.getState().bundle;
    }

    updateBundle(bundle: Bundle) {
        this.store.dispatch(
            CelesRedux.updateDataActionGenerator(
                'bundle',
                bundle,
                'UPDATE_BUNDLE'
            )
        );
    }

    getIsCheckOut(): boolean {
        return this.store.getState().isCheckOut;
    }


}

export default SignUpLoginPageDAL;
