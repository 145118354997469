//creates the Redux store, does any set-up
import * as Redux from "redux";
import CelesRedux from '../utility/globalRedux'

import AuthPageBLL from "./AuthPageBLL";
import AuthPageDAL from "./AuthPageDAL";
import FetchUtility from "../utility/fetchUtility";
import { RequestState } from "../utility/fetchTypes";
import { AppBLL } from "../app/AppBLL";
import { Bundle } from "../models/bundle";
import { Course } from "../landing/LandingPageStructure";

export type FormField = {
  value: string;
  error?: boolean;
  tooltip?: string;
}

export type AuthPageFormState = {
  email: FormField;
  password: FormField;
  confirmPassword: FormField;
  name: FormField,
  phoneNumber: FormField,
}

export interface AuthPageState {
  isSignUp: boolean;
  // is the user attempting to checkout
  isCheckOut: boolean;
  formState: AuthPageFormState;
  fetchStatus: RequestState;
  product?: Course;
  bundle?: Bundle;
}

class AuthPage {
  private baseApiURL: string;
  public store: any;
  private initialState: AuthPageState;
  public BLL: AuthPageBLL;
  public DAL: AuthPageDAL;

  constructor() {
    // if already logged in, redirect to home page
    if (AppBLL.LoggedIn()) {
      window.location.href = '/';
    }
    const isCheckOut = window.location.pathname.split('/').pop() !== 'auth';
    this.initialState = {
      isCheckOut,
      isSignUp: false,
      formState: {
        email: {
          value: ''
        },
        password: {
          value: ''
        },
        confirmPassword: {
          value: ''
        },
        name: {
          value: ''
        },
        phoneNumber: {
          value: ''
        },
      },
      product: null,
      bundle: null,
      fetchStatus: FetchUtility.getDefaultFetchRequestState(false),
    };

    const pageReducerArray: ReducerObject = CelesRedux.createPageReducer(this.initialState);
    const pageReducer: IReducer = Redux.combineReducers(pageReducerArray);

    this.store = Redux.createStore(
      pageReducer,
      this.initialState,
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    );

    this.DAL = new AuthPageDAL(this.store, this.baseApiURL);
    this.BLL = new AuthPageBLL(this.DAL);

    window.currentPage = this;
  }
}

export default AuthPage;