import { Provider } from 'react-redux';
import React from "react";

//importing logic layers
import '../../../src/styles/landing/landing.css';
import AuthPage from '../../logic/auth/AuthPageStructure';
import { AuthPageComponents } from './AuthPageComponents';
import { Toaster } from 'react-hot-toast';
import styled from 'styled-components';


interface AuthPageContainerProps extends PageViewProps {
}

class AuthPageContainer extends React.Component<AuthPageContainerProps, GenericObject> {

  private pageDataAndLogic: AuthPage;
  private pageComponents: AuthPageComponents;
  private AuthPageViewComponent: any;

  public constructor(props: AuthPageContainerProps) {

    super(props);
    this.pageDataAndLogic = new AuthPage();
    this.pageComponents = new AuthPageComponents(this.pageDataAndLogic);
    this.AuthPageViewComponent = this.pageComponents.createAuthPageView();
  }

  render() {

    return (
      <Provider store={this.pageDataAndLogic.store}>
        <Toaster />
        <PageContainer>
          <this.AuthPageViewComponent />
        </PageContainer>
      </Provider >
    )
  }

}

const PageContainer = styled.div`
   display: flex;
   justify-content: center;
`;

export default AuthPageContainer;

