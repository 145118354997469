import autobind from 'auto-bind';
import produce from 'immer';
import toast from 'react-hot-toast';
import AppDAL from '../app/AppDAL';
import { getProductInfoFromUrl, navigateToCheckout } from '../checkout/utils';
import { ProductType } from '../models/common';
import { AuthRequests } from '../serverRequests/authRequests';
import { fetchBundle, fetchCourseById } from '../serverRequests/productRequest';
import AuthPageDAL from './AuthPageDAL';
import { AuthPageFormState } from './AuthPageStructure';

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
}

class AuthPageBLL {
  private DAL: AuthPageDAL;

  constructor(PageDAL: AuthPageDAL) {
    this.DAL = PageDAL;
    autobind(this);
  }

  async initializePage() {
    const {
      productId,
      productType
    } = getProductInfoFromUrl();
    const isBundle = productType === ProductType.Bundle;
    try {
      if (isBundle) {
        const response = await fetchBundle({ bundleId: productId });
        this.DAL.updateBundle(response.data[0]);
      } else {
        const response = await fetchCourseById({ courseId: productId });
        this.DAL.updateCourse(response.data[0]);
      }
    } catch (error) {
      console.error(error);
    }

  }

  updateFormState(formValues: AuthPageFormState) {
    this.DAL.updateFormState(formValues);
  }



  validateEmail(email: string): string | null {
    if (!email) {
      return 'Please enter an email address.';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return 'Please enter a valid email address.';
    } else {
      return null;
    }
  }

  validatePassword(password: string): string | null {
    if (!password) {
      return 'Please enter a password.';
    } else if (password.length < 8) {
      return 'Your password must be at least 8 characters long.';
      // } else if (!/\d/.test(password)) {
      //   return 'Your password must contain at least one number.';
      // } else if (!/[a-z]/.test(password)) {
      //   return 'Your password must contain at least one lowercase letter.';
      // } else if (!/[A-Z]/.test(password)) {
      //   return 'Your password must contain at least one uppercase letter.';
      // } else {
      //   return null;
    }
    return null;
  }

  validateConfirmPassword(password: string, confirmPassword: string): string | null {
    if (password !== confirmPassword) {
      return 'Passwords do not match.';
    } else {
      return null;
    }
  }

  validateName(name: string): string | null {
    if (!name) {
      return 'Please enter your name.';
    } else {
      return null;
    }
  }

  validatePhoneNumber(phoneNumber: string): string | null {
    return !phoneNumber ? 'Please enter your phone number' : null;
  }

  // Validate the entire form
  validateForm() {
    const currentFormState = this.DAL.getFormState();
    let hasError = false;
    const validatedForm = produce(currentFormState, (draft: AuthPageFormState) => {
      const emailError = this.validateEmail(draft.email.value);
      const passwordError = this.validatePassword(draft.password.value);
      const phoneNumberError = this.validatePhoneNumber(draft.phoneNumber.value);
      const confirmPasswordError = this.validateConfirmPassword(
        draft.password.value, draft.confirmPassword.value);
      const nameError = this.validateName(draft.name.value);

      if (emailError) {
        draft.email.error = true;
        draft.email.tooltip = emailError;
        hasError = true;
      }

      if (passwordError) {
        draft.password.error = true;
        draft.password.tooltip = passwordError;
        hasError = true;
      }

      if (confirmPasswordError) {
        draft.confirmPassword.error = true;
        draft.confirmPassword.tooltip = confirmPasswordError;
        hasError = true;
      }

      if (phoneNumberError) {
        draft.phoneNumber.error = true;
        draft.phoneNumber.tooltip = phoneNumberError;
        hasError = true;
      }

      if (nameError) {
        draft.name.error = true;
        draft.name.tooltip = nameError;
        hasError = true;
      }

      return draft;
    });
    console.log(validatedForm);
    this.DAL.updateFormState(validatedForm);
    const isValid = !hasError;
    return isValid;
  }

  // reset form state to initial state
  resetFormState() {
    this.DAL.updateFormState({
      email: {
        value: ''
      },
      password: {
        value: ''
      },
      confirmPassword: {
        value: ''
      },
      name: {
        value: ''
      },
      phoneNumber: {
        value: ''
      }
    });
  }

  // Handle the form submission
  async handleSubmit() {
    const { email, password, name, phoneNumber } = this.DAL.getFormState();
    const isSignUp = this.DAL.getIsSignUp();
    const isValid = this.validateForm();
    let response;

    try {
      if (isSignUp) {
        if (!isValid) {
          toast.error('Please fix the errors in the form before submitting.')
          return;
        }
        response = await AuthRequests.fetchSignUpUser({
          email: email.value,
          password: password.value,
          name: name.value,
          phoneNumber: phoneNumber.value
        });

      } else {
        response = await AuthRequests.fetchLoginUser({
          email: email.value,
          password: password.value
        });
      }
      // add the user and token to the local store
      AppDAL.setSelectedUser(response.user);
      AppDAL.setToken(response.tokenData.token);
      if (this.DAL.getIsCheckOut()) {
        const { productId, productType } = getProductInfoFromUrl();
        navigateToCheckout({
          productId,
          type: productType,
        });
        return;
      }
      window.location.href = '/';
    } catch (error) {
      if (error) {
        toast.error(error);
        return;
      }
      toast.error('We could not find an account matching those credentials.');
    }
  }

  toggleIsSignUp() {
    this.DAL.updateIsSignUp(!this.DAL.getIsSignUp());
    this.resetFormState();
  }

}

export default AuthPageBLL;
