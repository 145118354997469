import { connect, ConnectedComponent } from "react-redux";

import LandingPage, { LandingPageState } from "../../logic/landing/LandingPageStructure";
import { HelpModalView } from "./HelpModal";
import LandingPageView from "./LandingPage";

class LandingPageComponents {

    private pageDataAndLogic: LandingPage;

    constructor(pageDataAndLogic: LandingPage) {
        this.pageDataAndLogic = pageDataAndLogic;
    }

    public createLandingPageView() {

        const mapStateToComponentProps = (state: LandingPageState) => {
            return {
                courses: state.courses
            };
        };

        const BLL = this.pageDataAndLogic.BLL;

        const mapLogicToComponentProps = () => {
            return {
                handleFetchCourses: BLL.handleFetchCourses
            };
        };

        return connect(mapStateToComponentProps, mapLogicToComponentProps)(LandingPageView);
    }

}

export { LandingPageComponents };
