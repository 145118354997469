    /**
     * ENUMS *************************************************************************************
     */
    enum ActionTypes {
        UPDATE_DATA = "UPDATE_DATA",
        FETCH_REQUEST = "FETCH_REQUEST"
    }

    enum ValidationStrategyTypes {
        REQUIRED = "REQUIRED",
        DATE = "DATE",
        NONE = "NONE",
        REQUIRED_LENGTH = "REQUIRED_LENGTH"
    }

    enum FormErrorTypes {
        REQUIRED = "REQUIRED"
    }

    
    export {ActionTypes, ValidationStrategyTypes, FormErrorTypes};