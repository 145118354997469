import React, { FC } from 'react';
import '../../../src/styles/landing/landing.css';
import styled from 'styled-components';
import { Button } from 'src/styles/common/styles';
import { ProductType } from 'src/logic/models/common';
import { navigateToCheckout } from 'src/logic/checkout/utils';

export const GiftCardSection = () => {
    return (
        <ProductCardContainer className='product-card-container'>
            <ProductImage src="/img/aol_gift_card.webp" />
            <ProductInfoContainer>
                <ProductName>
                    Buy Art of Living <br />
                    <span style={{
                        color: '#FFC000'
                    }}>Cash eGift Card</span>
                </ProductName>
                <SecondaryText>
                    Use it towards an Art of Living program of your choice.
                    <br />
                    <br />
                    Choose an amount.
                </SecondaryText>

                <GiftCardButtonContainer>
                    <Button onClick={() => navigateToCheckout({
                        productId: 8,
                        type: ProductType.Product
                    })}>
                        $50
                    </Button>
                    <Button onClick={() => navigateToCheckout({
                        productId: 9,
                        type: ProductType.Product
                    })}>
                        $75
                    </Button>
                    <Button onClick={() => navigateToCheckout({
                        productId: 10,
                        type: ProductType.Product
                    })}>
                        $100
                    </Button>
                    <Button onClick={() => navigateToCheckout({
                        productId: 11,
                        type: ProductType.Product
                    })}>
                        $200
                    </Button>
                </GiftCardButtonContainer>
            </ProductInfoContainer>
        </ProductCardContainer>
    )
}


const GiftCardButtonContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-top: 20px;
`;

const ProductImage = styled.img`
    border-radius: 36px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    height: 507px;
`;

const ProductName = styled.p`
    font-family: Libre-Baskerville;
    font-size: 42px;
    font-weight: 600;
`;

const ProductCardContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 100px;
`;

const ProductInfoContainer = styled.div`
    position: relative;
    padding: 10px;
    padding-left: 20px;
    max-width: 500px;
    width: 100%;
`;

const SecondaryText = styled.p`
    color: #3A3A3A;
    font-size: 18px;
    left: 20px;
`;
