import autobind from 'auto-bind';
import CelesRedux from '../utility/globalRedux';

import FetchUtility from '../utility/fetchUtility';
import AppDAL from '../app/AppDAL';
import produce from 'immer';
import { ServerConfig } from '../serverRequests/serverConfig';
import { RequestState } from '../utility/fetchTypes';
import { Course } from '../landing/LandingPageStructure';
import { Bundle } from '../models/bundle';


class ProductPageDAL {

    protected store: any;
    private baseApiURL: string;

    constructor(ReduxStore: any, BaseApiURL: string) {
        this.store = ReduxStore;
        this.baseApiURL = BaseApiURL;

        autobind(this);
    }

    // getter/setter

    getCourse(): Course {
        return this.store.getState().courses;
    }

    updateCourse(course: Course) {
        this.store.dispatch(CelesRedux.updateDataActionGenerator('course', course, 'UPDATE_COURSE'));
    }

    // getter/setter
    getBundles(): Array<Bundle> {
        return this.store.getState().bundles;
    }

    updateBundles(bundles: Array<Bundle>) {
        this.store.dispatch(CelesRedux.updateDataActionGenerator('bundles', bundles, 'UPDATE_BUNDLES'));
    }

}


export default ProductPageDAL;