
import autobind from 'auto-bind';
import { fetchBundlesForCourse, fetchCourseById } from '../serverRequests/productRequest';
import ProductPageDAL from './ProductPageDAL';

class ProductPageBLL {
    private DAL: ProductPageDAL;
    constructor(PageDAL: ProductPageDAL) {
        this.DAL = PageDAL;
        autobind(this);
    }

    async handleFetchBundles({ courseId }: { courseId: string }) {
        const response = await fetchBundlesForCourse({ courseId });
        const bundles = response.data;
        this.DAL.updateBundles(bundles);
    }

    async handleFetchCourse({ courseId }: { courseId: string }) {
        const response = await fetchCourseById({ courseId });
        const course = response.data;
        this.DAL.updateCourse(course[0]);
    }

}

export default ProductPageBLL;