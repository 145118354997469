import AppDAL from "./AppDAL";

//globally accessible
export class AppBLL {

    public static signOut(){
        AppDAL.clearSession();
        window.location.href = '/';
    }

    // public static IsAdmin(): boolean {
    //     return AppDAL.getUserInfo().role === "SITE_ADMIN";
    // }

    public static LoggedIn(): boolean{
        if (AppDAL.getSelectedUser() !== null && AppDAL.getToken() !== null){
            return true;
        }
        return false;
    }

}

window.AppBLL = AppBLL;
