import { User } from "../models/user";
import FetchUtility from "../utility/fetchUtility";
import { ServerConfig } from "./serverConfig";

const fetchSignUpUserEndpoint = `${ServerConfig.serverBaseURL}/create-user`;
const fetchLoginUserEndpoint = `${ServerConfig.serverBaseURL}/login`;

export type UserInfoWithToken = {
    user: User,
    tokenData: {
        token: string,
        expiresIn: number
    }
};

export class AuthRequests {

    static async fetchLoginUser({ email, password }: {
        email: string;
        password: string;
    }) {
        const response = await fetch(fetchLoginUserEndpoint,
            {
                method: 'post',
                headers: new Headers({
                    'Content-type': 'application/json; charset=UTF-8'
                }),
                body: JSON.stringify({
                    email,
                    password
                })
            });
        //check if response was an error 
        if (FetchUtility.isSuccessResponse(response)) {
            const data: UserInfoWithToken = await response.json();
            return data;

        } else {
            const responseObj: GenericObject = await response.json()
            throw responseObj.message;
        }

    }

    static async fetchSignUpUser({ email, password, name, phoneNumber }:
        {
            email: string,
            password: string,
            name: string,
            phoneNumber: string
        }) {
        const requestBody = {
            email: email,
            password: password,
            name: name,
            phoneNumber: phoneNumber
        };

        const response = await fetch(fetchSignUpUserEndpoint,
            {
                method: 'post',
                headers: new Headers({
                    'Content-type': 'application/json; charset=UTF-8'
                }),
                body: JSON.stringify(requestBody)
            });

        //check if response was an error 
        if (FetchUtility.isSuccessResponse(response)) {
            const data: UserInfoWithToken = await response.json();
            return data;

        } else {
            const responseObj: GenericObject = await response.json()
            throw responseObj.message;
        }

    }

}