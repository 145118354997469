//creates the Redux store, does any set-up
import * as Redux from "redux";
import CelesRedux from '../utility/globalRedux'

import CheckoutPageBLL from "./CheckoutPageBLL";
import CheckoutPageDAL from "./CheckoutPageDAL";
import { Bundle } from "../models/bundle";
import { Course } from "../landing/LandingPageStructure";
import FetchUtility from "../utility/fetchUtility";
import { RequestState } from "../utility/fetchTypes";
import { AppBLL } from "../app/AppBLL";
import { getProductInfoFromUrl } from "./utils";

export interface CheckoutPageState {
    course?: Course;
    bundle?: Bundle;
    isBundle: boolean;
    paymentFetchStatus: RequestState;
    isGift: boolean;
    recipientEmail: string;
}

class CheckoutPage {

    private baseApiURL: string;

    public store: any;
    // TODO: add type to initialState
    private initialState: any;
    public BLL: CheckoutPageBLL;
    public DAL: CheckoutPageDAL;

    constructor() {
        this.initialState = {
            course: null,
            bundle: null,
            isBundle: false,
            paymentFetchStatus: FetchUtility.getDefaultFetchRequestState(false),
            isGift: false,
            recipientEmail: ''
        };

        const pageReducerArray: ReducerObject = CelesRedux.createPageReducer(this.initialState);
        const pageReducer: IReducer = Redux.combineReducers(pageReducerArray);

        this.store = Redux.createStore(pageReducer, this.initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

        this.DAL = new CheckoutPageDAL(this.store, this.baseApiURL);
        this.BLL = new CheckoutPageBLL(this.DAL);

        // if is not logged in redirect to auth page
        if (!AppBLL.LoggedIn()) {
            const { productId, productTypeUrlChar } = getProductInfoFromUrl();
            window.location.href = `/auth/${productTypeUrlChar}/${productId}`;
        }

        window.currentPage = this;
    }

}

export default CheckoutPage;
