import { connect } from "react-redux";

import CheckoutPage, { CheckoutPageState } from "../../logic/checkout/CheckoutPageStructure";
import { CheckoutFormContainer } from "./CheckoutForm";

class CheckoutPageComponents {

    private pageDataAndLogic: CheckoutPage;

    constructor(pageDataAndLogic: CheckoutPage) {
        this.pageDataAndLogic = pageDataAndLogic;
    }

    public createCheckoutPageView() {

        const mapStateToComponentProps = (state: CheckoutPageState) => {
            return {
                course: state.course,
                bundle: state.bundle,
                paymentFetchStatus: state.paymentFetchStatus,
                isGift: state.isGift
            };
        };

        const BLL = this.pageDataAndLogic.BLL;

        const mapLogicToComponentProps = () => {
            return {
                initializePage: this.pageDataAndLogic.BLL.initializePage,
                handleFetchPaymentIntent: this.pageDataAndLogic.BLL.handleFetchPaymentIntent,
                handleFetchConfirmPayment: this.pageDataAndLogic.BLL.handleFetchConfirmPayment,
                handleSetPaymentFetchStatus: this.pageDataAndLogic.BLL.handleSetPaymentFetchStatus,
                handleGiftToggle: BLL.handleGiftToggle,
                handleRecipientEmailChange: BLL.handleRecipientEmailChange
            };
        };

        return connect(mapStateToComponentProps, mapLogicToComponentProps)(CheckoutFormContainer);
    }

}

export { CheckoutPageComponents };
