import { connect } from "react-redux";

import ProductPage, { ProductPageState } from "../../logic/product/ProductPageStructure";
import { ProductPageView } from "./ProductPage";

class ProductPageComponents {

    private pageDataAndLogic: ProductPage;

    constructor(pageDataAndLogic: ProductPage) {
        this.pageDataAndLogic = pageDataAndLogic;
    }

    public createProductPageView() {

        const mapStateToComponentProps = (state: ProductPageState) => {
            return {
                course: state.course,
                bundles: state.bundles
            };
        };

        const BLL = this.pageDataAndLogic.BLL;

        const mapLogicToComponentProps = () => {
            return {

            };
        };

        return connect(mapStateToComponentProps, mapLogicToComponentProps)(ProductPageView);
    }

}

export { ProductPageComponents };
