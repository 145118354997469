//REDUCERS: HANDLES ACTIONS
//handler for most important action which is updating the data

import { ActionTypes } from "./globalEnums";


class CelesRedux {

  static updateDataReducer(storeTarget: string, prevState: object, action: IAction) {
    if (action.storeTarget === storeTarget) {
      if (action.generalType === ActionTypes.UPDATE_DATA) {
        return action.payload;
      }
    }

    return prevState;
  }

  static updateDataActionGenerator(StoreTarget: string, Payload: any, Type?: string, ) {

    let type: string;

    if (typeof Type == null) {
      type = ActionTypes.UPDATE_DATA + "_" + StoreTarget;
    } else {
      type = Type;
    }

    return {
      type: type,
      generalType: ActionTypes.UPDATE_DATA,
      storeTarget: StoreTarget,
      payload: Payload
    };
  }

  static createPageReducer(InitialPageState: GenericObject){
    
    let pageReducers: ReducerObject = {};

    for (const key in InitialPageState) {
      let initalValue = InitialPageState[key];
      let reducer: IReducer =  function(prevState: object = initalValue, action: IAction) {
       return CelesRedux.updateDataReducer(key, prevState, action);
      }
      pageReducers[key] = reducer;
    }

    return pageReducers;
  }


}



export default CelesRedux;