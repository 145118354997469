
import autobind from 'auto-bind';
import AppDAL from '../app/AppDAL';
import { ProductType } from '../models/common';
import { fetchBundle, fetchBundlesForCourse, fetchCourseById, fetchPaymentIntent, fetchPurchaseProduct } from '../serverRequests/productRequest';
import { RequestState } from '../utility/fetchTypes';
import CheckoutPageDAL from './CheckoutPageDAL';
import { getProductInfoFromUrl } from './utils';

class CheckoutPageBLL {
    private DAL: CheckoutPageDAL;
    constructor(PageDAL: CheckoutPageDAL) {
        this.DAL = PageDAL;
        autobind(this);
    }

    async handleFetchBundles({ courseId }: { courseId: string }) {
        const response = await fetchBundlesForCourse({ courseId });
        const bundles = response.data;
        this.DAL.updateBundle(bundles[0]);
    }

    async handleFetchCourse({ courseId }: { courseId: string }) {
        const response = await fetchCourseById({ courseId });
        const course = response.data;
        this.DAL.updateCourse(course[0]);
    }

    async initializePage() {
        const {
            productId,
            productType
        } = getProductInfoFromUrl();
        const isBundle = productType === ProductType.Bundle;
        this.DAL.updateIsBundle(isBundle);
        if (isBundle) {
            const response = await fetchBundle({ bundleId: productId });
            this.DAL.updateBundle(response.data[0]);
        } else {
            const response = await fetchCourseById({ courseId: productId });
            this.DAL.updateCourse(response.data[0]);
        }
    }

    async handleFetchPaymentIntent() {
        const userId = AppDAL.getSelectedUser().user_id;
        const isGift = this.DAL.getIsGift();
        const recipientEmail = this.DAL.getRecipientEmail();

        // create payment intent
        const isBundle = this.DAL.getIsBundle();
        const productId = isBundle ? this.DAL.getBundle().bundle_id : this.DAL.getCourse().course_id;
        const data = await fetchPaymentIntent({
            productId,
            isBundle,
            userId,
            recipientEmail: isGift ? recipientEmail : undefined
        });

        return data;
    }

    async handleFetchConfirmPayment({ paymentIntentId }: { paymentIntentId: string }) {
        try {
            // get user id
            const userId = AppDAL.getSelectedUser().user_id;
            const data = await fetchPurchaseProduct({ paymentIntentId, userId });

            return data;
        } catch (err) {
            throw err;
        }
    }

    handleSetPaymentFetchStatus({ fetchStatus }: { fetchStatus: RequestState }) {
        this.DAL.updatePaymentFetchStatus(fetchStatus);
    }

    handleGiftToggle() {
        const isGift = this.DAL.getIsGift();
        this.DAL.updateIsGift(!isGift);
    }

    handleRecipientEmailChange({ recipientEmail }: { recipientEmail: string }) {
        this.DAL.updateRecipientEmail(recipientEmail);
    }

}

export default CheckoutPageBLL;