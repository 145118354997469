import React, { ReactElement } from "react";
import { AppHeader } from '../common/Header';
import styled from "styled-components";

const TermsOfService = (): JSX.Element => {
    return (
        <TermsOfServicePageContainer>
            <AppHeader />
                <TermsOfServiceTextContainer>
                <div>
        <div>
          <h1>Terms of Use</h1>
          <p><u><strong>Acceptance of the Terms of Use</strong></u></p>
          <p>Welcome to the website of Art of Living, Canada on behalf of itself and its affiliates and
            licensors, licensees or service providers (“Organization”, also referred to as “us”, “our” or “we”). These
            Terms of Use governs the users (“you” or “your”) access to and use of our website, including any content,
            functionality and services offered on or through our website, and the treatment of any personal data that
            you choose to submit.</p>
          <p>Each time you use this website, you accept and agree to be bound and abide by these Terms of Use, incorporated herein by reference. This Terms of Use is for an indefinite term and you
            understand and agree that you are bound by such terms, if you do not agree to these Terms of Use, please do not access or use the website.</p>
          <p><u><strong>Changes to the Terms of Use</strong></u></p>
          <p>We may revise and update these Terms of Use from time to time in our sole discretion as per applicable law.
            All future changes are effective and binding when they are posted unless the applicable law provides
            otherwise.</p>
          <p>If you use the website following the posting of revised Terms of Use you accept and agree to the changes.
            Unless the applicable law provides otherwise, it is your responsibility to check this page from time to time
            so you are aware of any changes, as they are binding on you if you choose to continue to access or use the
            website.”</p>
          <p><u><strong>Accessing the Website and Account Security</strong></u></p>
          <p>We reserve the right to amend or modify the Terms of Use of this website, including but not limited to
            suspend or discontinue any service or material we provide on the website, in our sole discretion, at any
            time without notice, make modifications and alterations in any or all of the content, products and services
            contained on the website without prior notice. We will not be liable if for any reason all or any part of
            the website is unavailable at any time or for any period. From time to time, we may restrict access to some
            parts of the website, or the entire website, to users, including registered users. You will be deemed to
            have accepted such change(s) if you continue to access this website subsequent to such changes.</p>
          <p>You are responsible for:</p>
          <ul>
            <li>Making all arrangements necessary for you to have access to the website.</li>
            <li>Ensuring that all persons who access the website through your internet connection are aware of these
              Terms of Use and comply with them.</li>
          </ul>
          <p>If you choose, or are provided with, a user name, password or other identifier, or any other piece of
            information (“User Data”) as part of our security procedures, you must treat such information as
            confidential, and you must not disclose it to any other person or entity. You also acknowledge that your
            account is personal to you and agree not to provide any other person with access to this website or portions
            of it using your User Data. You agree to notify us immediately of any unauthorized access to or use of your
            User Data or any other breach of security. You also agree to ensure that you exit from your account at the
            end of each session. You should use particular caution when accessing your account from a public or shared
            computer so that others are not able to view or record your User Data or other personal information.</p>
          <p>We have the right to disable any User Data, whether chosen by you or provided by us, at any time in our
            sole discretion if, in our opinion, you have violated any provision of these Terms of Use or we become aware
            of a potential breach of security through the use of your User Data.</p>
          <p><u><strong>Intellectual Property Rights</strong></u></p>
          <p>The website and its entire contents, features and functionality (including but not limited to all
            information, software, text, displays, images, video and audio, and the design, selection and arrangement
            thereof), are owned by the Organization, its affiliates, its licensors or other providers of such material
            and are protected by national and international copyright, trademark, patent, trade secret and other
            intellectual property or proprietary rights laws. Any infringement shall be vigorously prosecuted and
            pursued to the fullest extent permitted by law.</p>
          <p>These Terms of Use permit you to use the website for your personal, non-commercial use only. You must not
            reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish,
            download, store or transmit any of the material on our website, except as follows:</p>
          <ul>
            <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and
              viewing those materials.</li>
            <li>You may store files that are automatically cached by your web browser for display enhancement purposes.
            </li>
            <li>You may print or download one copy of a reasonable number of pages of the website for your own personal,
              non-commercial use and not for further reproduction, publication or distribution.</li>
            <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your
              computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound
              by our end user license agreement for such applications.</li>
            <li>If we provide social media with certain features, you may take such actions as are enabled by such
              features.</li>
          </ul>
          <p>You must not:</p>
          <ul>
            <li>Modify copies of any materials from this site.</li>
            <li>Use any illustrations, photographs, video or audio sequences or any graphics separately from the
              accompanying text.</li>
            <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials
              from this site.</li>
          </ul>
          <p>You must not access or use for any commercial purposes any part of the website or any services or materials
            available through the website.</p>
          <p>If you wish to make any use of material on the website other than that set out in this section, please
            address your request to:&nbsp;<a href="mailto:donations@artofliving.ca">donations@artofliving.ca</a>.
          </p>
          <p>If you print, copy, modify, download or otherwise use or provide any other person with access to any part
            of the website in breach of the Terms of Use, your right to use the website will cease immediately and you
            must, at our option, return or destroy any copies of the materials you have made. No right, title or
            interest in or to the website or any content on the site is transferred to you, and all rights not expressly
            granted are reserved by the Organization. Any use of the website not expressly permitted by these Terms of
            Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>
          <p><u><strong>Prohibited Uses</strong></u></p>
          <p>You may access and use the website only for lawful purposes and in accordance with these Terms of Use.
            Following is a partial list of the kind of content that is illegal or prohibited to post on or through the
            website. The Organization reserves the right to investigate and take appropriate legal action against anyone
            who, in the Organization’s sole discretion, violates this provision, including without limitation, removing
            the offending communication from the website and terminating the usage of such violators. Prohibited content
            includes, but is not limited to content that, in the sole discretion of Organization:</p>
          <ul>
            <li>is patently offensive and promotes racism, bigotry, hatred or physical harm of any kind against any
              group or individual;</li>
            <li>harasses or advocates harassment of another user;</li>
            <li>exploits people in a sexual or violent manner;</li>
            <li>contains nudity, violence, or offensive subject matter or contains a link to an adult website;</li>
            <li>solicits personal information from anyone under 18;</li>
            <li>provides any telephone numbers, addresses, last names, urls or email addresses;</li>
            <li>promotes information that you know is false or misleading or promotes illegal activities or conduct that
              is abusive, threatening, obscene, defamatory or libelous;</li>
            <li>violates any applicable national, state, local or international law or regulation (including, without
              limitation, any laws regarding the export of data or software to and from other countries);</li>
            <li>harms or attempts to exploit or harm minors in any way by exposing them to inappropriate content, asking
              for personally identifiable information or otherwise.</li>
            <li>promotes an illegal or unauthorized copy of another person’s copyrighted work, such as providing pirated
              computer programs or links to them, providing information to circumvent manufacture-installed copy-protect
              devices, or providing pirated music or links to pirated music files;</li>
            <li>sends, knowingly receives, uploads, downloads, uses or re-uses any material which does not comply with
              the standards set out herein;</li>
            <li>Involves the transmission, of any advertising or promotional material without our prior written consent
              including any “junk mail,” “chain letter” or “spam” or any other similar unsolicited contains restricted
              or password only access pages or hidden pages or images (those not linked to or from another accessible
              page);</li>
            <li>furthers or promotes any criminal activity or enterprise or provides instructional information about
              illegal activities including, but not limited to making or buying illegal weapons, violating someone’s
              privacy, or providing or creating computer viruses;</li>
            <li>solicits passwords or personal identifying information for commercial or unlawful purposes from other
              users;</li>
            <li>involves commercial activities and/or sales without our prior written consent such as contests,
              sweepstakes, barter, advertising, or pyramid schemes;</li>
            <li>includes a photograph or video of another person that you have posted without that person’s consent;
            </li>
            <li>criminal or torturous activity, including child pornography, fraud, trafficking in obscene material,
              drug dealing, gambling, harassment, stalking, spamming, sending of viruses or other harmful files,
              copyright infringement, patent infringement, or theft of trade secrets;</li>
            <li>advertising to, or solicitation of, any User to buy or sell any products or services through the
              Organization’s services. You may not transmit any chain letters or junk email to other User’s. It is also
              a violation of these rules to use any information obtained from the Organization’s website in order to
              contact, advertise to, solicit, or sell to any User without their prior explicit consent. If you breach
              any provisions of these Terms of Use and send any unsolicited communications of any kind through the
              website, you acknowledge that you will have caused substantial harm to the Organization, but that the
              amount of such harm would be extremely difficult to ascertain;</li>
            <li>covering or obscuring the banner advertisements on your personal profile page, or any of the
              Organization’s pages;</li>
            <li>any automated use of the system, such as using scripts to add friends or send comments or messages;</li>
            <li>attempts to impersonate the Organization, an Organization employee, another user or any other person or
              entity (including, without limitation, by using e-mail addresses associated with any of the foregoing);
            </li>
            <li>engages in any other conduct that restricts or inhibits anyone’s use or enjoyment of the website, or
              which, as determined by us, may harm the Organization or users of the website or expose them to liability;
            </li>
            <li>using the account, username, or password of another User at any time or disclosing your password to any
              third party or permitting any third party to access your account;</li>
            <li>selling or otherwise transferring your profile;</li>
            <li>using any information obtained from the website in order to harass, abuse, or harm another person;</li>
            <li>displaying an advertisement on your profile, or accepting payment or anything of value from a third
              person in exchange for your performing any commercial activity on or through the website on behalf of that
              person, such as placing commercial content on your profile, posting blogs or bulletins with a commercial
              purpose, selecting a profile with a commercial purpose, or sending private messages with a commercial
              purpose</li>
          </ul>
          <p>Additionally, you agree not to:</p>
          <ul>
            <li>Use the website in any manner that could potentially disable, overburden, damage, or impair the website
              or interfere with any other party’s use of the website, including their ability to engage in real time
              activities through the website.</li>
            <li>Use any robot, spider or other automatic device, process or means to access the website for any purpose,
              including monitoring or copying any of the material on the website.</li>
            <li>Use any manual process to monitor or copy any of the material on the website or for any other
              unauthorized purpose without our prior written consent.</li>
            <li>Use any device, software or routine that interferes with the proper working of the website.</li>
            <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or
              technologically harmful.</li>
            <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the website, the
              server on which the website is stored, or any server, computer or database connected to the website.</li>
            <li>Attack the website via a denial-of-service attack or a distributed denial-of-service attack.</li>
            <li>Otherwise attempt to interfere with the proper working of the website.</li>
          </ul>
          <p><u><strong>User Contributions</strong></u></p>
          <p>The website may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards
            and other interactive features (collectively, “Interactive Services”) that allow users to post, submit,
            publish, display or transmit to other users or other persons (hereinafter, “post”) content or materials
            (collectively, “User Contributions”) on or through the website.</p>
          <p>All User Contributions must comply with the content standards as set out herein above in Prohibited Uses.
          </p>
          <p>Any User Contributions you post to the site will be considered non-confidential and non-proprietary. By
            providing any User Contributions on the website, you grant us and our affiliates and service providers, and
            each of their and our respective licensees, successors and assigns the right to use, reproduce, modify,
            perform, display, distribute and otherwise disclose to third parties any such material.</p>
          <p>You represent and warrant that:</p>
          <ul>
            <li>You own or control all rights in and to the User Contributions and have the right to grant the license
              granted above to us and our affiliates and service providers, and each of their and our respective
              licensees, successors and assigns.</li>
            <li>All of your User Contributions do and will comply with these Terms of Use.</li>
            <li>You understand and acknowledge that you are responsible for any User Contributions you submit or
              contribute, and you, not the Organization, have full responsibility for such content, including its
              legality, reliability, accuracy and appropriateness.</li>
            <li>You understand and acknowledge that we are not responsible, or liable to any third party, for the
              content or accuracy of any User Contributions posted by you or any other user of the website.</li>
          </ul>
          <p><u><strong>Monitoring and Enforcement; Termination</strong></u></p>
          <p>We have the right to:</p>
          <ul>
            <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
            <li>Take any action with respect to any User Contributions that we deem necessary or appropriate in our sole
              discretion, including if we believe that such User Contributions violate the Terms of Use, infringe any
              intellectual property right or other right of any person or entity, threaten the personal safety of users
              of the website or the public or could create liability for the Organization.</li>
            <li>Disclose your identity or other information about you to any third party who claims that material posted
              by you violates their rights, including their intellectual property rights or their right to privacy.</li>
            <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any
              illegal or unauthorized use of the website.</li>
            <li>Terminate or suspend your access to all or part of the website for any violation of these Terms of Use.
            </li>
          </ul>
          <p>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities
            or court order requesting or directing us to disclose the identity or other information of anyone posting
            any materials on or through the website.</p>
          <p>You agree to hold harmless the Organization from any claims resulting from any action taken by or on behalf
            of the Organization during or as a result of its investigations and from any actions taken as a consequence
            of investigations by either the organization/such parties or law enforcement authorities with respect to
            your User Contributions, and agree to waive any claims against the Organization resulting from any such
            actions where your User Contributions are found to be in violation of these Terms of Use.</p>
          <p>However, we do not undertake to review all material before it is posted on the website, and cannot ensure
            prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for
            any action or inaction regarding transmissions, communications or content provided by any user or third
            party or for performance or nonperformance of the activities described in this section.</p>
          <p><u><strong>No Reliance on Information Posted</strong></u></p>
          <p>Information presented on or through the website is made available solely for general information purposes.
            We do not warrant the accuracy, completeness or usefulness of any information on the website. Any reliance
            you place on such information is strictly at your own risk. We disclaim all liability and responsibility
            arising from any reliance placed on such materials by you or any other visitor to the website, or by anyone
            who may be informed of any of its contents.</p>
          <p>This website may include content provided by third parties, including materials provided by other users,
            bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements
            and/or opinions expressed in these materials, and all articles and responses to questions and other content,
            other than the content provided by the Organization, are solely the opinions and the responsibility of the
            person or entity providing those materials. We are not responsible, or liable to you or any third party, for
            the content or accuracy of any materials provided by any third parties.</p>
          <p><u><strong>Advertising Material</strong></u></p>
          <p>Part of the website may contain advertising/other material submitted to us by third parties. Responsibility
            for ensuring that material submitted for inclusion on the website complies with applicable international and
            national law is exclusively on the advertisers and we will not be responsible for any claim, error, omission
            or inaccuracy in advertising material. We reserve the right to omit, suspend or change the position of any
            advertising material submitted for insertion.</p>
          <p><u><strong>Information About You and Your Visits to the Website</strong></u></p>

          <p><u><strong>Non transferability</strong></u></p>
          <p>You agree that your account on the website is non-transferable and any rights to your ID or contents with
            your said account automatically terminate upon your death. Upon receipt of a copy of a death certificate,
            your account may be terminated and all contents therein permanently deleted.</p>
          <p><u><strong>Linking to the Website and Social Media Features</strong></u></p>
          <p>You may link to our homepage, provided you do so in a way that is fair and legal and does not damage our
            reputation or take advantage of it, but you must not establish a link in such a way as to suggest any form
            of association, approval or endorsement on our part without our express written consent.</p>
          <p>This website may provide certain social media features that enable you to:</p>
          <ul>
            <li>Link from your own or certain third-party websites to certain content on this website.</li>
            <li>Send e-mails or other communications with certain content, or links to certain content, on this website.
            </li>
            <li>Cause limited portions of content on this website to be displayed or appear to be displayed on your own
              or certain third-party websites.</li>
          </ul>
          <p>You may use these features solely as they are provided by us, and solely with respect to the content they
            are displayed with and otherwise in accordance with any additional terms and conditions we provide with
            respect to such features. Subject to the foregoing, you must not:</p>
          <ul>
            <li>Establish a link from any website that is not owned by you.</li>
            <li>Cause the website or portions of it to be displayed, or appear to be displayed by, for example, framing,
              deep linking or in-line linking, on any other site.</li>
            <li>Link to any part of the website other than the homepage.</li>
            <li>Otherwise take any action with respect to the materials on this website that is inconsistent with any
              other provision of these Terms of Use.</li>
          </ul>
          <p>You agree to cooperate with us in causing any unauthorized framing or linking immediately to cease. We
            reserve the right to withdraw linking permission without notice.</p>
          <p>We may disable all or any social media features and any links at any time without notice in our discretion.
          </p>
  
          <p><u><strong>Links from the Website</strong></u></p>
          <p>If the website contains links to other sites and resources provided by third parties, these links are
            provided for your convenience only. This includes links contained in advertisements, including banner
            advertisements and sponsored links. Please be aware that we are not responsible for the privacy practices,
            or content, of these websites. We encourage our users to be aware when they leave our website and to read
            the privacy statements of these websites. You should evaluate the security and trustworthiness of any other
            website connected to this website or accessed through this website yourself, before disclosing any personal
            information to them. We have no control over the contents of those sites or resources, and accept no
            responsibility for them or for any loss or damage that may arise from your use of them. If you decide to
            access any of the third party websites linked to this website, you do so entirely at your own risk and
            subject to the terms and conditions of use for such websites.</p>
          <p><u><strong>Disclaimer of Warranties and Liability</strong></u></p>
          <p>You acknowledge and understand that we cannot and do not guarantee that files available for downloading
            from the internet or the website will be free of viruses or other destructive code. You are responsible for
            implementing sufficient procedures and checks to satisfy your particular requirements for anti-virus
            protection and accuracy of data input and output, and for maintaining a means external to our site for any
            reconstruction of any lost data.</p>
          <p>We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or
            other technologically harmful material that may infect your computer equipment, computer programs, data or
            other proprietary material due to your use of the website or any services or items obtained through the
            website or to your downloading of any material posted on it, or on any website linked to it.</p>
          <p>Your use of the website is at your own risk, and its content and any services or items obtained through the
            website are provided on an “as is” and “as available” basis.</p>
          <p>All the contents of the website are only for general information or use. They do not constitute advice and
            should not be relied upon in making (or refraining from making) any decision and you should consult an
            appropriate professional for specific advice tailored to your situation. Any specific advice or replies to
            queries in any part of the website is/are the personal opinion of such persons and are not subscribed to by
            the website or us. The information from or through the website is provided on “AS IS” basis, and all
            warranties, expressed or implied of any kind, regarding any matter pertaining to any goods, service or
            channel, including without limitation, the implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement are disclaimed and excluded.</p>
          <p>The Organization hereby disclaims any warranty, whether express or implied, statutory or otherwise,
            including but not limited to any warranty of merchantability, non-infringement and fitness for particular
            purpose, completeness, security, reliability, quality, accuracy or uninterrupted or error-free availability
            of the website, its content or any services or items obtained through the website, that defects will be
            corrected, that the Organization’s website or the server that makes it available are free of viruses or
            other harmful components or that the website or any services or items obtained through the website will
            otherwise meet your needs or expectations.</p>
          <p>The foregoing does not affect any warranties that cannot be excluded or limited under applicable law.</p>
          <p><u><strong>Limitation on Liability</strong></u></p>
          <p>In no event will the Organization, or its employees, agents, officers or directors be liable for damages of
            any kind, under any legal theory, arising out of or in connection with your use, or inability to use, the
            website, any websites linked to it, any content on the website or such other websites or any services or
            items obtained through the website or such other websites, including any direct, indirect, special,
            incidental, consequential or punitive damages, including but not limited to, personal injury, pain and
            suffering, emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings,
            loss of use, loss of goodwill, loss of data, and whether caused by tort (including negligence), breach of
            contract or otherwise, even if foreseeable.</p>
          <p>The foregoing does not affect any liability that cannot be excluded or limited under applicable law.</p>
          <p><u><strong>Indemnification</strong></u></p>
          <p>You agree to defend, indemnify and hold harmless the Organization, as well as its respective officers,
            directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any
            claims, liabilities, damages, investigations, judgments, awards, losses, costs, expenses or fees (including
            reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use
            of the website, including, but not limited to, your User Contributions, any use of the website’s content,
            services and products other than as expressly authorized in these Terms of Use or your use of any
            information obtained from the website.</p>
          <p><u><strong>Governing Law and Jurisdiction</strong></u></p>
          <p>All matters relating to the website and these Terms of Use and any dispute or claim arising therefrom or
            related thereto (in each case, including non-contractual disputes or claims), shall be governed by and
            construed in accordance with the internal laws of Canada without giving effect to any choice or conflict of
            law provision or rule (whether of Canada or any other jurisdiction).</p>
          <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the website shall
            be instituted exclusively in Canada, although we retain the right to bring any suit, action or proceeding
            against you for breach of these Terms of Use in your country of residence or any other relevant country if
            appropriate. You waive any and all objections to the exercise of jurisdiction over you by such courts and to
            venue in such courts.</p>
          <p><u><strong>Force Majeure</strong></u></p>
          <p>We shall have no liability to you for any interruption or delay in access to the website irrespective of
            the cause.</p>
          <p><u><strong>Waiver and Severability</strong></u></p>
          <p>No waiver of by the Organization of any term or condition set forth in these Terms of Use shall be deemed a
            further or continuing waiver of such term or condition or a waiver of any other term or condition, and any
            failure of the Organization to assert a right or provision under these Terms of Use shall not constitute a
            waiver of such right or provision.</p>
          <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be
            invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of the Terms of Use will continue in full force and
            effect.</p>
          <p><u><strong>Entire Agreement</strong></u></p>
          <p>The Terms of Use constitute the sole and entire agreement between you and the
            Organization with respect to the website and supersede all prior and contemporaneous understandings,
            agreements, representations and warranties, both written and oral, with respect to the website.</p>
          <p><u><strong>Your Comments and Concerns</strong></u></p>
          <p>This website is operated by Art of Living, Canada.</p>
          <p>All feedback, comments, requests for technical support and other communications relating to the website
            should be directed to:&nbsp;<a href="mailto:donations@artofliving.ca">donations@artofliving.ca</a>.</p>
          <p>Thank you for visiting the website.</p>
        </div>
      </div>
                </TermsOfServiceTextContainer>
        </TermsOfServicePageContainer>

);
}

const TermsOfServicePageContainer = styled.div`
display: flex;
justify-content: center;
flex-direction: column;
h1 {
    margin: 0 0 20px 0;
    font-size: 20px;
    font-weight: 500;
}
padding: 50px;
`;

const TermsOfServiceTextContainer = styled.div`
    margin-top: 50px;
`;




export default TermsOfService;
