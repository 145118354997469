
import { ServerInfo } from "./AppModels";
import { User } from "../models/user";

//AppDAL methods are static because it should be defined globally for all the pages
class AppDAL {

    /* Session Storage */ 
    /* FUTURE: switch to indexed based browser database like PouchDB */
    public static getSelectedUser() {
        const selectedUser: User = JSON.parse(localStorage.getItem('selectedUser'));
        return selectedUser;
    }

    public static setSelectedUser(selectedUser: any){
        const selectedUserStr: string = JSON.stringify(selectedUser);
        localStorage.setItem('selectedUser', selectedUserStr);        
    }

    public static getToken(){
        return localStorage.getItem('token');
    }

    public static setToken(token: string){
        localStorage.setItem('token', token);
    }

    public static clearSession(){
        console.log('session cleared');
        localStorage.clear();
    }

    public static getServerInfoState (): ServerInfo {
        const serverInfoState: ServerInfo = JSON.parse(localStorage.getItem('serverInfo'));
        serverInfoState.authAPI.tokenExpiryDate = new Date(serverInfoState.authAPI.tokenExpiryDate);
        serverInfoState.authAPI.refreshTokenExpiryDate = new Date(serverInfoState.authAPI.refreshTokenExpiryDate);
        return serverInfoState;
    }

    public static updateServerInfoState(serverInfoState: ServerInfo){
        const serverInfoStr: string = JSON.stringify(serverInfoState);
        localStorage.setItem('serverInfo', serverInfoStr);        
    }

}

window.AppDAL = AppDAL;

export default AppDAL;