//creates the Redux store, does any set-up

import * as Redux from "redux";
import CelesRedux from '../utility/globalRedux'
import LandingPageBLL from "./LandingPageBLL";
import LandingPageDAL from "./LandingPageDAL";

export type Course = {
  course_id: number,
  price: number,
  discount?: number,
  name?: string,
  details: {
    name: string,
    description: string
    imageUrl: string,
    isAdvanced?: boolean
  }
}

export interface LandingPageState {
  courses: Array<Course>;
}

class LandingPage {

  private baseApiURL: string;

  public store: any;
  // TODO: add type to initialState
  private initialState: any;
  public BLL: LandingPageBLL;
  public DAL: LandingPageDAL;

  constructor() {
    this.initialState = {
      courses: []
    };

    const pageReducerArray: ReducerObject = CelesRedux.createPageReducer(this.initialState);
    const pageReducer:IReducer = Redux.combineReducers(pageReducerArray);

    this.store = Redux.createStore(pageReducer, this.initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

    this.DAL = new LandingPageDAL(this.store, this.baseApiURL);
    this.BLL = new LandingPageBLL(this.DAL);


    window.currentPage = this;
  }

}

export default LandingPage;
