import { connect } from "react-redux";

import AuthPage, { AuthPageState } from "../../logic/auth/AuthPageStructure";
import AuthPageView from "./AuthPage";

class AuthPageComponents {

    private pageDataAndLogic: AuthPage;

    constructor(pageDataAndLogic: AuthPage) {
        this.pageDataAndLogic = pageDataAndLogic;
    }

    public createAuthPageView() {

        const mapStateToComponentProps = (state: AuthPageState) => {
            return {
                formData: state.formState,
                isSignUp: state.isSignUp,
                product: state.product,
                bundle: state.bundle

            };
        };

        const BLL = this.pageDataAndLogic.BLL;

        const mapLogicToComponentProps = () => {
            return {
                handleUpdateForm: BLL.updateFormState,
                handleSubmit: BLL.handleSubmit,
                handleToggleIsSignUp: BLL.toggleIsSignUp,
                initializePage: BLL.initializePage
            };
        };

        return connect(mapStateToComponentProps, mapLogicToComponentProps)(AuthPageView);
    }

}

export { AuthPageComponents };
