import { Provider } from 'react-redux';
import React from "react";

//importing logic layers
import '../../../src/styles/landing/landing.css';
import { CheckoutFormContainer } from './CheckoutForm';
import CheckoutPage from 'src/logic/checkout/CheckoutPageStructure';
import { CheckoutPageComponents } from './CheckoutPageComponents';
import { fetchBundle } from 'src/logic/serverRequests/productRequest';


interface CheckoutPageContainerProps extends PageViewProps {
}

class CheckoutPageContainer extends React.Component<CheckoutPageContainerProps, GenericObject> {

    private pageDataAndLogic: CheckoutPage;
    private pageComponents: CheckoutPageComponents;
    private CheckoutPageViewComponent: any;

    public constructor(props: CheckoutPageContainerProps) {

        super(props);
        this.pageDataAndLogic = new CheckoutPage();
        this.pageComponents = new CheckoutPageComponents(this.pageDataAndLogic);
        this.CheckoutPageViewComponent = this.pageComponents.createCheckoutPageView();
    }



    render() {

        return (
            <Provider store={this.pageDataAndLogic.store}>
                <this.CheckoutPageViewComponent />
            </Provider >
        )
    }

}

export default CheckoutPageContainer;

