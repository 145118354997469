import React, { useEffect } from 'react';
import { Form, Button, Segment, Divider } from 'semantic-ui-react';
import { AuthPageFormState } from 'src/logic/auth/AuthPageStructure';
import { Course } from 'src/logic/landing/LandingPageStructure';
import { Bundle } from 'src/logic/models/bundle';
import styled from 'styled-components';
import { AuthBundleInfoContainer, BundleContainer, CourseContainer } from '../product/ProductPage';

type SignUpProps = {
  formData: AuthPageFormState;
  handleUpdateForm: (pageState: AuthPageFormState) => void;
  handleSubmit: () => void;
  isSignUp: boolean;
  handleToggleIsSignUp: () => void;
  product?: Course;
  bundle?: Bundle;
  initializePage: () => void;
};

const AuthPageView: React.FC<SignUpProps> = ({
  formData,
  handleUpdateForm,
  isSignUp,
  handleSubmit,
  handleToggleIsSignUp,
  initializePage,
  bundle,
  product
}) => {
  const FormComponent = isSignUp ? SignUpForm : LoginForm;

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  return (
    <FormContainer>
      <div>
        <SignUpText>
          <b>
            {!isSignUp ? 'Login' : 'Sign up'} to proceed to checkout
          </b>
        </SignUpText>
        {bundle && (<AuthBundleInfoContainer bundle={bundle} />)}
        {product && (<CourseContainer course={product} />)}
      </div>
      <FormComponent
        formData={formData}
        handleUpdateForm={handleUpdateForm}
        handleSubmit={handleSubmit}
        handleToggleIsSignUp={handleToggleIsSignUp}
      />
    </FormContainer>
  );
};

const SignUpForm = ({
  formData,
  handleUpdateForm,
  handleSubmit,
  handleToggleIsSignUp
}: {
  formData: AuthPageFormState;
  handleUpdateForm: (pageState: AuthPageFormState) => void;
  handleSubmit: () => void;
  handleToggleIsSignUp: () => void;
}) => {
  return (<Form>
    <Form.Field className='checkout-field' error={formData.name.error}>
      <label>Name</label>
      <input placeholder='Enter your name'
        onChange={(e) => {
          handleUpdateForm({
            ...formData,
            name: { value: e.target.value }
          });
        }} />
    </Form.Field>
    <Form.Input className='checkout-field' label="Email" error={formData.email.error}>
      <input placeholder='Enter your email'
        onChange={(e) => {
          handleUpdateForm({
            ...formData,
            email: { value: e.target.value }
          });
        }}
      />
    </Form.Input>
    <Form.Input className='checkout-field'
      label='Phone Number'
      error={formData.phoneNumber.error ? {
        content: formData.phoneNumber.tooltip,
        pointing: 'below',
      } : null}>
      <input placeholder='Enter your phone number'
        onChange={(e) => {
          handleUpdateForm({
            ...formData,
            phoneNumber: { value: e.target.value }
          });
        }}
      />
    </Form.Input>
    <Form.Input className='checkout-field' label="Password" error={formData.password.error}>
      <input type='password'
        onChange={(e) => {
          handleUpdateForm({
            ...formData,
            password: { value: e.target.value }
          });
        }}
      />
    </Form.Input>
    <Form.Input
      label="Confirm Password"
      className='checkout-field'
      error={formData.confirmPassword.error}>
      <input type='password'
        onChange={(e) => {
          handleUpdateForm({
            ...formData,
            confirmPassword: { value: e.target.value }
          });
        }} />
    </Form.Input>
    <SubmitButton
      style={{ width: '100%', marginBottom: '20px' }}
      className='green-gradient'
      onClick={() => {
        handleSubmit();
      }}
    >
      Sign Up
    </SubmitButton>
    <Divider horizontal>Already have an account</Divider>
    <SubmitButton
      onClick={handleToggleIsSignUp}
    >
      Proceed to Log In
    </SubmitButton>
  </Form>);
}

const LoginForm = ({
  formData,
  handleUpdateForm,
  handleSubmit,
  handleToggleIsSignUp
}: {
  formData: AuthPageFormState;
  handleUpdateForm: (pageState: AuthPageFormState) => void;
  handleSubmit: () => void;
  handleToggleIsSignUp: () => void;
}) => {
  return (<Form>
    <Form.Field className='checkout-field' required>
      <label>Email</label>
      <input placeholder='Enter your email'
        onChange={(e) => {
          handleUpdateForm({
            ...formData,
            email: { value: e.target.value }
          });
        }} />
    </Form.Field>
    <Form.Field className='checkout-field' required>
      <label>Password</label>
      <input type='password'
        onChange={(e) => {
          handleUpdateForm({
            ...formData,
            password: { value: e.target.value }
          });
        }} />
    </Form.Field>
    <SubmitButton
      className='green-gradient'
      onClick={handleSubmit}
    >
      Log In
    </SubmitButton>
    <Divider horizontal>Or</Divider>
    <SubmitButton
      onClick={handleToggleIsSignUp}
    >
      Create Account
    </SubmitButton>
  </Form >)
}

const SubmitButton = styled(Button)`
  width: 100%;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FormContainer = styled(Segment)`
  display: flex;
  justify-content: center;
  min-width: 300px;
  max-width: 430px;
  flex-direction: column;
  margin-top: 100px !important;
`;

const SignUpText = styled.div`
margin-bottom: 20px;
`;

export default AuthPageView;
