import React from 'react';
import '../../../src/styles/landing/landing.css';
import { Label } from 'semantic-ui-react'
import styled from 'styled-components';
import { Button } from 'src/styles/common/styles';
import { ProductDetails, ProductIdToDetailsMap } from './ProjectDetails';
import { navigateToCheckout } from 'src/logic/checkout/utils';
import { ProductType } from 'src/logic/models/common';

interface ProductCardProps {
    id: number,
    title: string,
    description: string,
    price: number,
    discountPrice?: number,
    tag?: string,
    productImageUrl: string,
    onAddToCartClickFunc?: Function,
    onLearnMoreClickFunc?: Function
    isAdvanced?: boolean
}

const ProductCard = (props: ProductCardProps) => {
    const productDetails: ProductDetails = ProductIdToDetailsMap[props.id];

    if (!productDetails) {
        return null;
    }
    
    return (
        <ProductCardContainer className='product-card-container'>
            <ProductImage src={productDetails.productImage} />
            <ProductInfoContainer>
                <ProductName>
                    {productDetails.header}
                </ProductName>
                <SecondaryText>
                    {productDetails.description}
                    <br />
                    {props.discountPrice && <span className='discount-price'> ${props.price - props.discountPrice} CAD </span>}
                    <br/>
                    <div className={props.discountPrice && 'slashed-text'}>Price: ${props.price} CAD</div>
                    <Button onClick={() => navigateToCheckout({
                        productId: props.id,
                        type: ProductType.Product
                    })}>
                        {productDetails.buttonText}
                    </Button>
                </SecondaryText>
            </ProductInfoContainer>
        </ProductCardContainer>
    )
}

const ProductImage = styled.img`
    border-radius: 36px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    height: 507px;
`;

const ProductName = styled.p`
    font-family: Libre-Baskerville;
    font-size: 42px;
    font-weight: 600;
`;

const ProductCardContainer = styled.div`
    display: flex;
    position: relative;
    justify-content: space-around;
    width: 100%;
`;

const ProductInfoContainer = styled.div`
    position: relative;
    padding: 10px;
    padding-left: 20px;
    max-width: 500px;
    width: 100%;
`;

const SecondaryText = styled.div`
    color: #3A3A3A;
    font-size: 18px;
`;

export default ProductCard;
