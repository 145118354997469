import autobind from 'auto-bind';
import CelesRedux from '../utility/globalRedux';
import { Course } from '../landing/LandingPageStructure';
import { Bundle } from '../models/bundle';
import { RequestState } from '../utility/fetchTypes';


class CheckoutPageDAL {

    protected store: any;
    private baseApiURL: string;

    constructor(ReduxStore: any, BaseApiURL: string) {
        this.store = ReduxStore;
        this.baseApiURL = BaseApiURL;

        autobind(this);
    }

    // getter/setter

    getCourse(): Course {
        return this.store.getState().course;
    }

    updateCourse(course: Course) {
        this.store.dispatch(CelesRedux.updateDataActionGenerator('course', course, 'UPDATE_COURSE'));
    }

    // getter/setter
    getBundle(): Bundle {
        return this.store.getState().bundle;
    }

    updateBundle(bundle: Bundle) {
        this.store.dispatch(CelesRedux.updateDataActionGenerator('bundle', bundle, 'UPDATE_BUNDLE'));
    }

    getIsBundle(): boolean {
        return this.store.getState().isBundle;
    }

    updateIsBundle(isBundle: boolean) {
        this.store.dispatch(CelesRedux.updateDataActionGenerator(
            'isBundle', isBundle, 'UPDATE_IS_BUNDLE'));
    }

    getPaymentFetchStatus(): RequestState {
        return this.store.getState().paymentFetchStatus;
    }

    updatePaymentFetchStatus(fetchStatus: RequestState) {
        this.store.dispatch(CelesRedux.updateDataActionGenerator(
            'paymentFetchStatus', fetchStatus, 'UPDATE_PAYMENT_FETCH_STATUS'));
    }

    getIsGift(): boolean {
        return this.store.getState().isGift;
    }

    updateIsGift(isGift: boolean) {
        this.store.dispatch(CelesRedux.updateDataActionGenerator(
            'isGift', isGift, 'UPDATE_IS_GIFT'));
    }

    getRecipientEmail(): string {
        return this.store.getState().recipientEmail;
    }

    updateRecipientEmail(recipientEmail: string) {
        this.store.dispatch(CelesRedux.updateDataActionGenerator(
            'recipientEmail', recipientEmail, 'UPDATE_RECIPIENT_EMAIL'));
    }

}

export default CheckoutPageDAL;