import React, { ReactNode } from "react";

export type ProductLevel = 'Beginner' | 'Advanced';

export type ProductDetails = {
    name: string;
    shortName: string;
    description: ReactNode;
    thumbnailImage: string;
    header?: ReactNode;
    buttonText?: string;
    productImage: string;
}

export const ProductIdToDetailsMap: Record<number, ProductDetails> = {
    4: {
        name: 'Art of Living Part 1',
        shortName: 'AOL Part 1',
        description: 
            <>
                Learn Sudarshan Kriya™—a powerful rhythmic breathing technique that harmonizes the body-mind complex. 
                Participants notice reduced stress and anxiety, better sleep, a stronger immune system, and increased energy levels.
            </>,
        header: <>Help Your Loved <br />
        One Stress Less, <br />
        <span style={{
            color: '#FFC000'
        }}>Smile More, <br />
            Every Day!</span></>,
        buttonText: 'Gift Art of Living Part 1 Course',
        thumbnailImage: '/img/aol_part1_card_thumb.webp',
        productImage: '/img/aol_part1_card.webp',
    },
    5: {
        name: 'Art of Living Part 2',
        shortName: 'AOL Part 2',
        description: <>
            The Part 2 Course provides the ideal environment to dive deep within, breaking free from the constant chatter of the mind.
            Each day is carefully structured to offer a transformative and relaxing experience, a true vacation for your body, mind, and soul.
        </>,
        header: <>
            Gift a true <br />
            vacation for&nbsp;
            <span style={{
                color: '#FFC000'
            }}>
                body, <br/> mind, and soul
            </span>
        </>,
        buttonText: 'Gift Art of Living Part 2 Course',
        thumbnailImage: '/img/aol_part2_card_thumb.png',
        productImage: '/img/aol_part2_card.png',
    },
    7: {
        
            name: 'Sahaj Samadhi Meditation',
            shortName: 'Sahaj Samadhi',
            description: <>
                Sahaj translates to "effortless", and Samadhi signifies a state of profound meditation. 
                In simple terms, it's a technique to effortlessly enjoy deep meditation, 
                as well as the profound rest and wealth of other benefits that meditation provides.
                    <br/>
                    <br/>
                You'll receive a personalized mantra, a charged sound, that makes meditation easier and more effective.
                </>,
            header: <>Share the Joy of <br />
            <span style={{
                color: '#FFC000'
            }}>Effortless <br />
                Meditation</span></>,
            buttonText: 'Gift Art of Living Sahaj Samadhi Meditation',
            thumbnailImage: '/img/aol_aom_card_thumb.webp',
            productImage: '/img/aol_aom_card.webp',
        },
    8: {
        name: 'Art of Living Gift Card',
        thumbnailImage: '/img/aol_gift_card_thumb.webp',
        productImage: '',
        shortName: '',
        description: '',

    },
    9:  {
        name: 'Art of Living Gift Card',
        thumbnailImage: '/img/aol_gift_card_thumb.webp',
        productImage: '',
        shortName: '',
        description: '',
    },
    10:  {
        name: 'Art of Living Gift Card',
        thumbnailImage: '/img/aol_gift_card_thumb.webp',
        productImage: '',
        shortName: '',
        description: '',
    },
    11:  {
        name: 'Art of Living Gift Card',
        thumbnailImage: '/img/aol_gift_card_thumb.webp',
        productImage: '',
        shortName: '',
        description: '',
    },

};
