//creates the Redux store, does any set-up

import * as Redux from "redux";
import CelesRedux from '../utility/globalRedux'

import AppStructure from "../app/AppStructure";
import ProductPageBLL from "./ProductPageBLL";
import ProductPageDAL from "./ProductPageDAL";
import { ValidationStrategyTypes } from "../utility/globalEnums";
import FetchUtility from "../utility/fetchUtility";
import { Bundle } from "../models/bundle";
import { Course } from "../landing/LandingPageStructure";


export interface ProductPageState {
    course?: Course;
    bundles: Array<Bundle>;
}

class ProductPage {

    private baseApiURL: string;

    public store: any;
    // TODO: add type to initialState
    private initialState: any;
    public BLL: ProductPageBLL;
    public DAL: ProductPageDAL;
    // course_id: number,
    // price: number,
    // discount?: number,
    // name?: string,
    // details: {
    //   name: string,
    //   description: string
    //   imageUrl: string,
    //   isAdvanced?: boolean
    // }
    constructor() {
        this.initialState = {
            course: {
                price: 0,
                discount: 0,
                name: '',
                details: {
                    name: '',
                    description: '',
                    imageUrl: ''
                }
            },
            bundles: []
        };

        const pageReducerArray: ReducerObject = CelesRedux.createPageReducer(this.initialState);
        const pageReducer: IReducer = Redux.combineReducers(pageReducerArray);
        const courseId = window.location.pathname.split("/").pop();

        this.store = Redux.createStore(pageReducer, this.initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

        this.DAL = new ProductPageDAL(this.store, this.baseApiURL);
        this.BLL = new ProductPageBLL(this.DAL);
        // fetch data
        this.BLL.handleFetchBundles({ courseId });
        this.BLL.handleFetchCourse({ courseId });

        window.currentPage = this;
    }

}

export default ProductPage;
