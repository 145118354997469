import styled from 'styled-components';
import { Button as SementicButton } from 'semantic-ui-react'


export const Button = styled(SementicButton)`
    font-size: 18px !important;
    font-weight: 600 !important;
    margin-top: 20px !important;
    border-radius: 15px !important;
    width: auto !important;
    &:hover {
        background: #FFC000 !important;
        filter: brightness(100%) !important;
    }
`;