import { RequestState } from './fetchTypes';

export default class FetchUtility {

    static isSuccessResponse(Response: Response){

        //200 = success
        //201 = created
        //202 = accepted
        //204 = no response
        if (Response.status === 200 || Response.status === 201 || Response.status === 202 || Response.status === 204){
            return true;
        }
        return false;
    }

    static createRequestState(Success : boolean, Error : boolean, ErrorMessage: string, SuccessMessage: string, Processing: boolean): RequestState{
        return {
            success: Success,
            error: Error,
            errorMessage: ErrorMessage,
            successMessage: SuccessMessage,
            processing: Processing
        };
    }

    static createSuccessfulRequestState(SuccessMessage: string){
        return {
            success: true,
            error: false,
            successMessage: SuccessMessage,
            processing: false
        };
    }

    static createErrorRequestState(ErrorMessage: string){
        return {
            success: false,
            error: true,
            errorMessage: ErrorMessage,
            processing: false
        };
    }

    static getDefaultFetchRequestState(processing: boolean): RequestState {
        return {
            success: false,
            error: false,
            errorMessage: "",
            successMessage: "",
            processing: processing
        };
    }
    
    
}