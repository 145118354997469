import React from "react";

//importing logic layers
import '../../../src/styles/landing/landing.css';
import { Course } from '../../logic/landing/LandingPageStructure';
import { Icon } from 'semantic-ui-react'
import ProductCard from '../product/ProductCard'; import { AppHeader } from '../common/Header';
import styled from 'styled-components';
import { GiftCardSection } from "../product/GiftCardSection";

interface LandingPageViewProps extends PageViewProps {
  handleFetchCourses: Function,
  courses: Array<Course>
}

class LandingPageView extends React.Component<LandingPageViewProps, GenericObject> {

  public constructor(props: LandingPageViewProps) {
    super(props);
  }

  componentDidMount() {
    this.props.handleFetchCourses();
  }

  render() {

    return (
      <>
        <AppHeader />
        <HeaderContainer>
          <HeaderContent>
            <HeaderTextContainer>
              <HeaderMainText>
                Gift Calm and Joy
              </HeaderMainText>
              <HeaderDescription>
                Help your loved one stress less, sleep better, and <br />
                find more joy this holiday season.
                <ExploreNowLink />
              </HeaderDescription>
            </HeaderTextContainer>
            <img className="header-image" src="/img/Giftcard-art-of-living-banner-1.png" alt="header-image" />
          </HeaderContent>
        </HeaderContainer>
        <PageContainer className="page-container">
          <div className='page-content'>
            <GiftContainer>
              <GiftLeftTextContainer>
                A Gift That <br /> Goes&nbsp;<span style={{ color: '#F3B200' }}>Beyond <br /> The Season</span>
              </GiftLeftTextContainer>
              <GiftRightTextContainer>
                This holiday season offer more than just a gift—give the experience of lasting peace, balance, and joy. With evidence based meditations and practical tools, your loved ones can reduce stress, improve sleep, and feel their best every single day.
                It's a gift that goes beyond the season, bringing daily calm and well-being that lasts a lifetime
              </GiftRightTextContainer>
            </GiftContainer>
          </div>
          <div id="products" className='product-grid-container'>
            {this.props.courses.map(course =>
            (course.course_id <= 7 && <ProductCard
              id={course.course_id}
              isAdvanced={course.details.isAdvanced}
              title={course.details.name}
              description={course.details.description}
              productImageUrl={course.details.imageUrl}
              price={course.price}
              discountPrice={course.discount}
            />)
            )}
            <GiftCardSection />
          </div>
        </PageContainer>
      </>
    )
  }
}


const ExploreNowLink = () => {
  return (
    <div onClick={() => {
      document.getElementById('products').scrollIntoView();
    }}
      style={{paddingTop: '15px'}}
    >
      <Link>Send a Gift Now<Icon style={{ marginLeft: '5px' }} name='arrow right' /></Link>
    </div>
  )
}

const Link = styled.p`
font-size: 18px !important;
padding-left: 5px;
cursor: pointer;
width: fit-content;
margin-left: -5px;
&:hover {
  color: #0000EE;
}
`;

const GiftContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 100px;
  gap: 187px;
  margin-top: 25px;

  p {
    font-size: 18px;
    color: white;
    padding-left: 80px;
    padding-right: 80px;
    position: relative;
    top: 144px;
  }

  h3 {
    font-size: 24px;
    font-weight: 600;
  }
`;

const GiftLeftTextContainer = styled.div`
  font-size: 42px;
  font-family: Libre-Baskerville;
  font-weight: 600;
`;

const GiftRightTextContainer = styled.div`
  font-size: 18px;
  max-width: 500px;
  padding-left: 30px;
  padding-right: 30px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0px;
  height: 600px;
  max-width: 1200px;
`;

const HeaderMainText = styled.div`
  font-family: Libre-Baskerville;
  font-size: 42px;
  font-weight: 700;
`;

const HeaderDescription = styled.div`
  color: #000;
`;

const HeaderTextContainer = styled.div`
  white-space: nowrap;
  position: relative;
  left: 100px;
  ${Link} {
    font-size: 18px;
  }
`;

const HeaderImage = styled.img`
  border-radius: 40px;
`;

const TestimonialTextContainer = styled.div`
  padding: 20px;
  padding-top: 30px;
  align-items: center;
  border-radius: 28px;
  border: 1px solid #FAEC00;
  background: #FFFCEA;
  width: 520px;
  display: flex;
  justify-content: center;
`;

const HeaderContainer = styled.div`
  width: 100%;
  margin-top: 68px;

  display: flex;
  justify-content: center;

  background: #F9D71D;

  ${Link} {
    width: 100%;
    font-size: 16px;
  }

  .header-image {
    max-height: 500px
  }

  @media only screen and (max-width: 700px) {

    .header-image {
      position: relative;
      right: -6px;
      top: 40px;
      max-height: 280px;
    }

    ${HeaderContent} {
      width: 100%;
      height: fit-content;
      flex-direction: column-reverse;
      padding-bottom: 40px;
    }
  
    ${HeaderImage} {
      width: 100%;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  
    ${HeaderTextContainer} {
      position: static;
      white-space: initial;
      margin-top: 50px;
      text-align: center;
    }

    ${HeaderDescription} {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
`;

const PageContainer = styled.div`
h1 {
  color: #EF9473;
  font-weight: 600;
}

@media only screen and (max-width: 700px) {
  margin-top: 0px;
  ${TestimonialTextContainer} {
    width: 100% !important;
  }


  .product-card-container {
    flex-direction: column;
    align-items: center;
    position: block;
  }

  ${GiftContainer} {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const ArrowIcon = ({
  reverse = false,
}) => <svg width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg" style={{
  transform: reverse ? 'rotate(180deg)' : 'rotate(0deg)',
}}>
    <path id="Arrow 1" d="M13.0607 0.939339C12.4749 0.353552 11.5251 0.353552 10.9393 0.939339L1.3934 10.4853C0.807611 11.0711 0.807611 12.0208 1.3934 12.6066C1.97919 13.1924 2.92893 13.1924 3.51472 12.6066L12 4.12132L20.4853 12.6066C21.0711 13.1924 22.0208 13.1924 22.6066 12.6066C23.1924 12.0208 23.1924 11.0711 22.6066 10.4853L13.0607 0.939339ZM13.5 32L13.5 2H10.5L10.5 32H13.5Z" fill="#53BE7E" />
  </svg>;


export default LandingPageView;
